import apiAction from 'common/actions/api'
import Filter from '../../FilterBuilder/Filter'

export const CHANGE_FILTER = 'cylera/group/CHANGE_FILTER'
export const CHANGE_SINGULAR_FILTER = 'cylera/group/CHANGE_SINGULAR_FILTER'
export const RESET_FILTERS = 'cylera/group/RESET_FILTERS'
export const SET_GROUP_UUID = 'cylera/group/SET_GROUP_UUID'
export const PERSIST_GROUP_FILTERS = 'cylera/group/PERSIST_GROUP_FILTERS'
export const SET_GROUP_COLUMN_VISIBILITIES =
  'cylera/group/SET_GROUP_COLUMN_VISIBILITIES'
export const CLEAR_DEVICE_GROUP_DETAILS =
  'cylera/group/CLEAR_DEVICE_GROUP_DETAILS'
export const UPDATE_QUERY_FILTER = 'cylera/group/UPDATE_QUERY_FILTER'
export const SET_SORT = 'cylera/group/SET_SORT'

const groupApi = apiAction('group')
const devicesApi = apiAction('devices', '/device')

export const deleteGroup = (group, success, failure) =>
  groupApi(
    'delete',
    { group },
    {
      method: 'POST',
      success,
      failure
    }
  )

export const removeDevicesFromGroup = (group, devices, success, failure) =>
  groupApi(
    'remove_devices',
    { group, devices },
    {
      method: 'POST',
      success,
      failure,
      cacheable: false
    }
  )

export const removeFilteredDevicesFromGroup = (
  group,
  filters,
  success,
  failure
) =>
  groupApi(
    'remove_filtered_devices',
    { ...Filter.serializeFilters(filters), group },
    {
      method: 'POST',
      success,
      failure,
      cacheable: false
    }
  )

export const renameGroup = (group, name, success, failure) =>
  groupApi(
    'details',
    {
      uuid: group,
      name: name
    },
    {
      method: 'PUT',
      cacheable: false,
      success,
      failure
    }
  )

export function changeFilter(filter, value, context = 'search') {
  return dispatch => {
    dispatch({
      type: CHANGE_FILTER,
      context: context,
      data: {
        filter: filter,
        value: value
      }
    })
  }
}

export function changeSingularFilter(filter, value, context = 'search') {
  return dispatch => {
    dispatch({
      type: CHANGE_SINGULAR_FILTER,
      context: context,
      data: {
        filter: filter,
        value: value
      }
    })
  }
}

export const loadCustomGroups = locationId =>
  groupApi('custom', { location_id: locationId })

export const loadDetails = uuid => groupApi('details', { uuid: uuid })

export const clearDeviceGroupDetails = () => ({
  type: CLEAR_DEVICE_GROUP_DETAILS
})

export const setGroupUuid = groupUuid => ({
  type: SET_GROUP_UUID,
  data: groupUuid
})

export const updateGroupOwner = (
  groupUuid,
  ownerId,
  successCallback,
  failureCallback
) => {
  return groupApi(
    'update_owner',
    {
      group_uuid: groupUuid,
      owner_id: ownerId
    },
    {
      method: 'POST',
      success: successCallback,
      failure: failureCallback,
      cacheable: false
    }
  )
}

export const getGroupFilters = () => groupApi('group_filters')

export const getGroupsOwnedCount = () => groupApi('groups_owned_count')

export const getMyGroupingsInfinite = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  query,
  locationId = undefined
) => {
  return devicesApi(
    'my_groupings',
    {
      location_id: locationId,
      page: page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection,
      query: query
    },
    { infinite: true }
  )
}

export const getMyGroupings = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  query,
  locationId = undefined
) => {
  return devicesApi('my_groupings', {
    location_id: locationId,
    page: page,
    page_size: pageSize,
    sort_key: sortKey,
    sort_direction: sortDirection,
    query: query
  })
}

export function persistGroupFilters(activeFilters, presetFilters) {
  return dispatch => {
    dispatch({
      type: PERSIST_GROUP_FILTERS,
      data: {
        active: activeFilters,
        preset: presetFilters
      }
    })
  }
}

export function updateQueryFilter(newQueryFilter) {
  return dispatch => {
    dispatch({
      type: UPDATE_QUERY_FILTER,
      data: newQueryFilter
    })
  }
}

export function setDeviceGroupColumnVisibilities(groupColumnVisibilities) {
  return dispatch => {
    dispatch({
      type: SET_GROUP_COLUMN_VISIBILITIES,
      data: groupColumnVisibilities
    })
  }
}

export function setSort({ sortKey, sortDirection } = {}) {
  return dispatch => {
    dispatch({
      type: SET_SORT,
      data: {
        sort_key: sortKey,
        sort_direction: sortDirection
      }
    })
  }
}
