import apiAction from 'common/actions/api'

export const CHANGE_FILTER = 'cylera/attributes/CHANGE_FILTER'
export const CHANGE_SINGULAR_FILTER = 'cylera/attributes/CHANGE_SINGULAR_FILTER'
export const RESET_FILTERS = 'cylera/attributes/RESET_FILTERS'
export const SET_SORT = 'cylera/attributes/SET_SORT'
export const SET_DEVICE_SEARCH_TEXT = 'cylera/attributes/SET_SEARCH_TEXT'

const attributesApi = apiAction('attributes', '/device')

export const getOrgAttributes = filterValues => {
  const { category, query } = filterValues || {}
  return attributesApi('org_attributes', { category, query })
}

export const getDevices = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  attributeLabel,
  filterValues
) => {
  const { category, query } = filterValues || {}
  return attributesApi('attribute_devices', {
    page,
    page_size: pageSize,
    sort_key: sortKey,
    sort_direction: sortDirection,
    attribute_label: attributeLabel,
    category,
    query
  })
}

export const getDevicesInfinite = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  attributeLabel,
  filterValues
) => {
  const { category, query } = filterValues || {}
  return attributesApi(
    'attribute_devices',
    {
      page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection,
      attribute_label: attributeLabel,
      category,
      query
    },
    { infinite: true, cacheable: true }
  )
}

export const exportDevices = (attributeLabel, filterValues) => {
  const { category, query } = filterValues || {}
  return attributesApi(
    'attribute_devices/export',
    {
      attribute_label: attributeLabel,
      category,
      query
    },
    { method: 'POST', cacheable: false }
  )
}

export function changeFilter(filter, value) {
  return dispatch => {
    dispatch({
      type: CHANGE_FILTER,
      data: {
        filter: filter,
        value: value
      }
    })
  }
}

export function changeSingularFilter(filter, value) {
  return dispatch => {
    dispatch({
      type: CHANGE_SINGULAR_FILTER,
      data: {
        filter: filter,
        value: value
      }
    })
  }
}

export function resetFilters() {
  return dispatch => {
    dispatch({
      type: RESET_FILTERS
    })
  }
}

export function setSort({ sortKey, sortDirection } = {}) {
  return dispatch => {
    dispatch({
      type: SET_SORT,
      data: {
        sort_key: sortKey,
        sort_direction: sortDirection
      }
    })
  }
}

export function setDeviceSearchText(searchText) {
  return dispatch => {
    dispatch({
      type: SET_DEVICE_SEARCH_TEXT,
      data: searchText
    })
  }
}
