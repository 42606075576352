import { selectIn } from 'common/util/selector'

const base = (state, props) => state.main.devices.attributes

export const list = selectIn(base, 'org_attributes')
export const listLoading = selectIn(base, 'org_attributes_loading')
export const filterValues = selectIn(base, 'filter_values')
export const devices = selectIn(base, 'attribute_devices')
export const devicesLoading = selectIn(base, 'attribute_devices_loading')
export const devicesTotal = selectIn(base, 'attribute_devices_total')
export const sortKey = selectIn(base, 'sort_key')
export const sortDirection = selectIn(base, 'sort_direction')
export const searchText = selectIn(base, 'search_text')
export const deviceSearchText = selectIn(base, 'device_search_text')
